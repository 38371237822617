import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import modal from './modules/modal';
import {
  uploadImage,
  getAllFramesData,
  getContacts,
  getImageURL,
  writeDoc,
  getDoc,
  hideFrame,
  showFrame,
  deleteFrame,
  swapItems,
  getAmountOfViews,
  updateAmountOfViews,
  getPasswords,
  updateAdminPassword,
  sendContacts,
  updateSuperAdminPassword,
  changeOrder,
} from '@/api/general.js';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { modal },

  state: {
    allFramesData: {},
    adminRole: null,
  },

  getters: {
    getAllFramesData: (state) => state.allFramesData,
    getAdminRole: (state) => state.adminRole,
  },
  mutations: {
    SET_ALL_FRAMES_DATA(state, payload) {
      state.allFramesData = payload;
    },
    SET_ADMIN_ROLE(state, payload) {
      state.adminRole = payload;
    },
  },
  actions: {
    async uploadImage({ commit }, data) {
      let res;
      try {
        res = await uploadImage(data.image, data.frameTitle, data.filename);
      } catch (error) {
        console.log(error);
      }
    },
    async getAllFramesData({ commit }) {
      let res;
      try {
        res = await getAllFramesData();
        commit('SET_ALL_FRAMES_DATA', res);
      } catch (error) {
        console.log(error);
      }
    },
    async getContacts({ commit }) {
      let res;
      try {
        res = await getContacts();
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    async getImageURL({ commit }, path) {
      let res;
      try {
        res = await getImageURL(path);
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    async writeDoc({ commit }, payload) {
      try {
        await writeDoc(payload.frameTitle, payload.documentForUpload);
      } catch (error) {
        console.log(error);
      }
    },
    async hideFrame({ commit }, payload) {
      try {
        await hideFrame(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async showFrame({ commit }, payload) {
      try {
        await showFrame(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteFrame({ commit }, payload) {
      try {
        await deleteFrame(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async getDoc({ commit }, folder) {
      try {
        return await getDoc(folder);
      } catch (error) {
        console.log(error);
      }
    },
    async getNumberOfFrames({ commit }) {
      try {
        const allFramesData = await getAllFramesData();
        return Object.keys(allFramesData).length;
      } catch (error) {
        console.log(error);
      }
    },
    async swapItems({ commit }, payload) {
      try {
        await swapItems(payload.titleFirst, payload.orderSecond);
        await swapItems(payload.titleSecond, payload.orderFirst);
      } catch (error) {
        console.log(error);
      }
    },
    async getAmountOfViews({ commit }) {
      let res;
      try {
        res = await getAmountOfViews();
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    async updateAmountOfViews({ commit }, data) {
      try {
        await updateAmountOfViews(data);
      } catch (error) {
        console.log(error);
      }
    },
    async signIn({ commit }, data) {
      let res;
      try {
        res = await getPasswords();
        if (data === res.admin) {
          commit('SET_ADMIN_ROLE', 'admin');
        } else if (data === res.superadmin) {
          commit('SET_ADMIN_ROLE', 'superAdmin');
        } else {
          alert('Невірний пароль');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateAdminPassword({ commit }, data) {
      try {
        await updateAdminPassword(data);
        alert('Пароль адміна змінено');
      } catch (error) {
        console.log(error);
      }
    },
    async updateSuperAdminPassword({ commit }, data) {
      try {
        await updateSuperAdminPassword(data);
        alert('Пароль суперадміна змінено');
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrentPasswords({ commit }) {
      let res;
      try {
        res = await getPasswords();
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    async sendContact({ commit }, data) {
      try {
        await sendContacts(data);
      } catch (error) {
        console.log(error);
      }
    },
    async changeOrder({ commit }, data) {
      try {
        await changeOrder(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
