<template>
  <div id="selector" class="selector">
    <div class="buttons__block">
      <div v-for="(button, id) in categoryButtons" :key="id">
        <ButtonBase
          category
          :category-transparent="!button.clicked"
          @click="toggleItem(button)"
          >{{ button.title }}</ButtonBase
        >
      </div>
    </div>
    <div class="selector__info">
      <svg-icon class="arrow-left" name="theme_arrow_left" />
      <svg-icon class="arrow-up" name="theme_arrow_up" />
      <p>Оберіть тему</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      categoryButtons: [
        { title: 'Всі теми', clicked: true },
        { title: 'Психологія війни', clicked: true },
        { title: 'Домедична допомога', clicked: true },
        { title: 'Корисні поради', clicked: true },
        { title: 'Військова справа', clicked: true },
      ],
    };
  },
  computed: {
    categoriesSelected() {
      let categoriesSelected = [];
      this.categoryButtons
        .filter((el) => el.clicked === true)
        .forEach((element) => {
          categoriesSelected.push(element.title);
        });
      return categoriesSelected;
    },
  },
  created() {
    this.$emit('categories-selected', this.categoriesSelected);
  },
  methods: {
    toggleItem(button) {
      if (button.title === 'Всі теми') {
        this.categoryButtons.forEach((button) => {
          button.clicked = true;
        });
      } else {
        button.clicked = !button.clicked;
      }
      this.categoryButtons.forEach((button) => {
        if (button.clicked === false) {
          this.categoryButtons[0].clicked = false;
        }
      });
      this.$emit('categories-selected', this.categoriesSelected);
    },
  },
};
</script>

<style lang="sass" scoped>
.selector
  display: flex
  padding: 2.6rem 0
  gap: 1.8rem
  align-items: center
  @include m
    flex-direction: column
    gap: 4.8rem

.buttons__block
  display: flex
  gap: 1.8rem
  flex-wrap: wrap
  @include ms
    gap: 1rem

.selector__info
  position: relative
  display: flex
  gap: 1rem
  align-items: center
  margin-left: auto
  white-space: nowrap

.arrow-left
  width: 8rem
  @include m
    display: none

.arrow-up
  position: absolute
  width: 8rem
  height: 8rem
  left: -8rem
  top: -5rem
  display: none
  @include m
    display: block
</style>
