<template>
  <div class="error-message">
    <span>{{ errorTxt }}</span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    errorTxt: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
.error-message
  display: flex
  align-items: center
  position: absolute
  bottom: -20px
  left: 0

  span
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: $negative-text
</style>
