<template>
  <div class="photo__wrapper-main">
    <div
      v-for="(photo, id_3) in photosUploaded"
      :key="id_3"
      class="uploaded-photo__wrapper"
    >
      <div class="uploaded-photo">
        <img id="preview" :src="photoPreview(photo)" alt="uploaded image" />
      </div>

      <div class="photos__loaded">
        <div class="photos__loaded-photo">
          <svg-icon class="photo__upload-icon" name="photo_upload" />
          <!-- <svg-icon class="loading-icon" name="loading_icon" /> -->
          <div class="progress__wrapper">
            <div class="progress__wrapper-photo">
              {{ photoName(photo) }}
              <span class="subtitle photo__upload-text">{{
                photoSize(photo)
              }}</span>
            </div>
            <!-- <div class="progress__wrapper-progress subtitle">
            Завантаження 37%
          </div> -->
          </div>
          <div
            v-if="!isDisabled"
            class="cross-icon"
            @click="removePhoto(photo)"
          >
            <svg-icon class="cross-icon" name="cross" />
          </div>
        </div>
      </div>
      <div v-if="isError" class="error-message">
        <template v-if="!!errorText">
          {{ errorText }}
        </template>
        <template v-else> Error </template>
      </div>
    </div>
    <div
      v-if="!isDisabled"
      class="photo__wrapper"
      @dragenter.prevent
      @dragover.prevent
      @drop.prevent
      @drop="handlePhotoDrop"
      @dragenter="dragging = true"
      @dragend="dragging = false"
      @dragleave="dragging = false"
    >
      <label class="input__photo-label" :for="'input__photo' + id">
        <div
          class="photo__drag-field"
          :class="{
            dragenter: dragging,
            'photo__drag-field_error': isError,
          }"
        >
          <svg-icon class="photo__upload-icon" name="photo_upload" />
          <input
            :id="'input__photo' + id"
            type="file"
            :accept="'.' + validFormats.join(',.')"
            :multiple="isMultiple"
            class="input"
            @change="handlePhotoInput"
          />
          <p class="photo__upload-text">
            Перетягніть
            <span class="subtitle">сюди файл зображення або оберіть</span>
          </p>
          <p v-if="showError" class="photo__upload-error">
            {{ errorMessage }}
          </p>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'photosUploaded',
    event: 'input',
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    photosUploaded: {
      type: Array,
      default: () => [],
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
    validFormats: {
      type: Array,
      default: () => [],
    },
    maxSizeKB: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showError: false,
      errorMessage: '',
      dragging: false,
      previewArray: [],
    };
  },
  computed: {
    toolTipText() {
      let toolTipText =
        'Разрешенные форматы: ' +
        '\n' +
        this.validFormats.join(', ') +
        '\n' +
        'Максимальный размер файла:' +
        this.maxSizeKB / 1000 +
        ' МБ';
      return toolTipText;
    },
  },
  created() {
    if (!this.filesUploaded) {
      this.$emit('input', []);
    }
  },
  methods: {
    photoPreview(photo) {
      return URL.createObjectURL(photo);
    },
    handlePhotoDrop(e) {
      this.dragging = false;
      let photos = e.dataTransfer.files;
      [...photos].forEach((f) => {
        if (!this.isMultiple && this.photosUploaded.length > 0) return;
        this.addPhoto(f);
      });
    },
    handlePhotoInput(e) {
      let photos = e.target.files;
      [...photos].forEach((f) => {
        if (!this.isMultiple && this.photosUploaded.length > 0) return;
        this.addPhoto(f);
      });
    },
    addPhoto(photo) {
      let photoFormat = photo.name.split('.').pop();

      // Validation

      if (!this.validFormats.includes(photoFormat.toLowerCase())) {
        this.errorMessage =
          'Доступные форматы: ' + this.validFormats.join(', ');
        this.showError = true;
        return 'break';
      } else if (photo.size >= this.maxSizeKB * 1000) {
        this.errorMessage =
          'Размер файла не может превышать: ' +
          (this.maxSizeKB / 1000).toString() +
          ' Мбайт';
        this.showError = true;
      } else if (this.checkIfSamePhotoUploaded(photo)) {
        this.errorMessage = 'Файл уже добавлен';
        this.showError = true;
      } else {
        this.previewArray.push(URL.createObjectURL(photo));
        this.photosUploaded.push(photo);
        this.$emit('input', this.photosUploaded);
        this.showError = false;
        this.$emit('dropError');
      }
    },
    photoSize(photo) {
      return `(${(photo.size / 1000000).toFixed(2)} Mbyte)`;
    },
    photoName(photo) {
      let photoName = photo.name.split('.');
      let photoExtension = photoName.pop();
      photoName = photoName.join().slice(0, 10) + '...' + photoExtension;
      return photoName;
    },
    removePhoto(item) {
      const index = this.photosUploaded.indexOf(item);
      if (index > -1) {
        this.photosUploaded.splice(index, 1);
        this.previewArray.splice(index, 1);
      }
      this.$emit('input', this.photosUploaded);
    },
    checkIfSamePhotoUploaded(photo) {
      let fileFound = false;
      this.photosUploaded.forEach((element) => {
        if (photo.size == element.size && photo.name == element.name) {
          fileFound = true;
        }
      });
      return fileFound;
    },
  },
};
</script>

<style lang="sass">
.photo__wrapper-main
  display: flex
  flex-wrap: wrap
  gap: 2rem

.photo__wrapper
  width: 24rem
  // width: 100%

.uploaded-photo__wrapper
  display: flex
  flex-direction: column

.uploaded-photo
  width: 24rem
  height: 24rem
  overflow: hidden
  & img
    width: 24rem
    height: 24rem
    object-fit: cover
    object-position: center

.photo__info
  display: flex
  align-items: center
  justify-content: end
  gap: 1rem

// .photo__tooltip
//   display: flex
//   flex-direction: column

.photo__info-icon, .photo__upload-icon, .cross-icon
  max-width: 2rem

.photo__upload-text, .photo__info-text, .photo__upload-error
  font-size: 1.2rem
  text-align: center

.photo__upload-error
  color: $negative-text
  margin: 0 1rem

.photo__drag-field
  max-width: 24rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 24rem
  border: 2px dashed $text-grey-color

.dragenter
  background-color: #0D326D05
  border: 2px dashed #0D326D


.photos__loaded
  margin-top: 0.5rem
  display: flex
  flex-direction: column
  gap: 0.5rem

.photos__loaded-photo
  display: flex
  align-items: center
  gap: 2rem
  max-height: 3rem

.loading-icon svg
  height: 100%

.progress__wrapper
  display: flex
  flex-direction: column

.progress__wrapper-photo
  font-size: 1.6rem
  display: flex
  flex-direction: column
  align-items: start

.progress__wrapper-progress
  font-size: 1.4rem

.cross-icon
  margin-left: auto
  cursor: pointer

.input
  display: none

.photo__drag-field_error, .photo__drag-field_error *
  border-color: $negative-text
  color: $negative-text
  fill: $negative-text
  &:hover
    border-color: $negative-text
</style>
