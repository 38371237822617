<template>
  <div id="contacts" class="contacts">
    <img src="@/assets/icons/emotic.png" class="info-right" alt="" />
    <!-- <p class="info-right">✊🙏💙</p> -->
    <svg-icon class="circle-right" name="contacts_circle_right" />
    <svg-icon class="arrow-right" name="contacts_arrow_right" />
    <div class="contacts__block">
      <div class="contacts__block-left">
        <p class="info-left">Маєте ідею?</p>
        <p class="info-center">Ви експерт?</p>
        <svg-icon class="circle-left" name="contacts_circle_left" />
        <svg-icon class="arrow-left" name="contacts_arrow_left" />

        <svg-icon class="circle-center" name="contacts_circle_center" />
        <svg-icon class="arrow-center" name="contacts_arrow_center" />
        <h4 class="contacts__block-left-text">
          Якщо ви маєте експертизу з&nbsp;питань психології війни, військової
          справи, домедичної допомоги, або маєте ідею теми, яку ми ще
          не&nbsp;розкрили — напишіть нам
        </h4>
        <ButtonBase
          class="contacts__block-left__button"
          transparent
          white
          big
          @click="contactUs"
          >Напишіть нам</ButtonBase
        >
        <div class="links__block">
          <a
            href="https://instagram.com/edera_ua?utm_medium=copy_link"
            target="_blank"
          >
            <svg-icon class="social-logo" name="instagram_logo" />
          </a>
          <a href="https://www.facebook.com/EdEraUa" target="_blank">
            <svg-icon class="social-logo" name="fb_logo" />
          </a>
          <a href="https://t.me/EdEra_ua" target="_blank">
            <svg-icon class="social-logo" name="telegram_logo" />
          </a>
          <a
            href="https://invite.viber.com/?g2=AQADdgyazBTe703G7B8WnP2zXxsEdo5fCSm%2FWoxnUTRLu2eXkD3vaZLILLSy8GW4&lang=ru"
            target="_blank"
          >
            <svg-icon class="social-logo" name="viber_logo" />
          </a>
        </div>
        <h4 class="start-cowork">Розпочати співпрацю</h4>
        <a
          class="link-light start-cowork__email"
          href="mailto:partnerships@ed-era.com"
          >partnerships@ed-era.com</a
        >
      </div>
      <div class="contacts__block-right">
        <h2 class="contacts__block-right__title">Дякуємо за вашу підтримку!</h2>
        <ButtonBase big class="contacts__block-right__button" transparent
          ><a
            class="link-dark"
            href="https://secure.wayforpay.com/button/b270edce44b8a"
            target="_blank"
            >Підтримати з картки</a
          ></ButtonBase
        >
        <ButtonBase big class="contacts__block-right__button" transparent>
          <a
            class="link-dark"
            href="https://firebasestorage.googleapis.com/v0/b/edera-30787.appspot.com/o/EdEra%20I%20Educational%20Era%20NGO%20bank%20details.docx.pdf?alt=media&token=44f82f1c-62f5-4277-8539-56d5b182ce88"
            target="_blank"
            >За реквізитами</a
          >
        </ButtonBase>
        <p class="contacts__block-right__text">
          Щодня ми робимо освіту в&nbsp;Україні більш якісною та доступною. Якщо
          ви розділяєте наші цінності та бажання покращити культуру
          онлайн-навчання, підтримайте проєкт донатами. Це допоможе нам
          із&nbsp;запуском нових форматів та&nbsp;розширенням можливостей сайту
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    contactUs() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'contact-us',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.contacts
  position: relative
  display: flex
  align-items: center
  position: relative
  padding: 20rem 0 0
  margin-bottom: 4.2rem
  margin-top: 12rem
  @include l
    margin-top: 0rem

.info-left, .info-center, .inf0-right
  @include xl
    font-size: 1.8rem
    line-height: 2.5rem
  @include l
    font-size: 1.6rem
    line-height: 2.24rem
  @include m
    font-size: 1.4rem
    line-height: 1.4rem
// .contacts-heart
//   position: absolute
//   max-width: 42rem
//   max-height: 36rem
//   top:0
//   left:0

.social-logo
  max-width: 5rem
  max-height: 5rem

.info-left
  position: absolute
  color: $text-primary-color
  max-width: 10rem
  text-align: center
  left: 15rem
  top: -16rem
  @include l
    top: -16rem
    left: 6rem
    max-width: 8rem
  @include m
    top: -15rem
  @include ms
    // font-size: 1.6rem
    // line-height: 2.2rem
    left: 0rem
    top: -15rem

.circle-left
  position: absolute
  left: 13rem
  top: -18rem
  max-width: 14rem
  max-height: 8rem
  @include l
    top: -18rem
    left: 3rem
  @include ms
    left: -3rem

.arrow-left
  position: absolute
  left: 21rem
  top: -13rem
  max-width: 16rem
  max-height: 12rem
  @include l
    top: -13rem
    left: 12rem
  @include ms
    left: 5rem

.info-center
  color: $text-primary-color
  position: absolute
  white-space: nowrap
  max-width: 18rem
  text-align: center
  left: 44rem
  top: -21rem
  @include l
    top: -21rem
    left: 35rem
  @include ms
    left: 23rem

.circle-center
  position: absolute
  left: 37rem
  top: -25rem
  max-width: 23rem
  max-height: 9rem
  @include l
    top: -25rem
    left: 28rem
  @include ms
    left: 15rem

.arrow-center
  position: absolute
  left: 38rem
  top: -17rem
  max-width: 16rem
  max-height: 16rem
  @include l
    top: -17rem
    left: 25rem
  @include ms
    left: 17rem

.info-right
  position: absolute
  // max-width: 18rem
  text-align: center
  right: 13rem
  top: 2rem
  @include m
    right: 4rem
  @include ms
    right: 5rem
  // @include xl
  //   font-size: 1.8rem
  //   line-height: 2.5rem
  // @include m
  //   font-size: 1.4rem
  //   line-height: 1.4rem

.circle-right
  position: absolute
  right: 10rem
  top: -1rem
  max-width: 14rem
  max-height: 8rem
  @include m
    right: 2rem

.arrow-right
  position: absolute
  right: 22rem
  top: 3rem
  max-width: 15rem
  max-height: 16rem
  @include m
    right: 14rem

.contacts__block
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 2.4rem
  @include m
    grid-gap: 30rem
    grid-template-columns: 1fr

  // display: flex
  // gap: 2.4rem
  // @include l
  //   flex-wrap: wrap
  //   flex-direction: column-reverse
  //   gap: 30rem

.contacts__block-left, .contacts__block-right
  position: relative
  flex: 1 1 50%
  border-radius: 4px
  padding: 5rem
  @include m
    padding: 2.5rem

.contacts__block-left
  background-color: $text-primary-color
  color: $white
  text-align: start
  @include l
    padding: 4.8rem
  @include m
    text-align: center
    grid-row: 2 / 3
    padding: 4.8rem
  @include ms
    padding: 3.4rem 2.2rem

.contacts__block-left-text
  @include xl
    font-size: 2rem
    line-height: 2.8rem
  @include m
    font-size: 1.6rem
    line-height: 2.2rem

.contacts__block-left__button
  margin: 2rem 0 5rem
  @include m
    margin: 2rem auto 5rem

.contacts__block-right
  background-color: rgba(233, 178, 79, 0.2)
  text-align: center
  background-image: url(../../assets/images/contacts_heart.svg)
  background-position: center
  background-size: 50%
  @include xl
    padding: 3.3rem 2.3rem

.contacts__block-right__button
  margin: 3rem auto
  @include xl
    font-size: 2.4rem
  @include ms
    margin: 2rem auto
    font-size: 2.2rem

.contacts__block-right__title
  max-width: 40rem
  margin: 0 auto 3rem

.contacts__block-right__text
  margin-top: 3rem
  @include xl
    font-size: 1.6rem
    line-height: 2.24rem

.links__block
  display: flex
  align-items: center
  gap: 3rem
  margin: 0 auto 4rem
  @include m
    justify-content: center
    margin: 0 auto 2rem

.social-logo
  max-width: 3.0rem
  max-height: 3.0rem
  fill: $white
  @include m
    max-width: 2.3rem
    max-height: 2.3rem

.link-light
  color: $white

.link-dark
  color: $text-primary-color
  @include xl
    font-size: 2.4rem

.start-cowork
  font-size: 2.4rem
  @include m
    font-size: 2.0rem

.start-cowork__email
  font-size: 4rem
  line-height: 5.6rem
  @include xl
    font-size: 2rem
    line-height: 2.8rem
  // @include m
  //   font-size: 2rem
  //   line-height: 2.8rem

.start-cowork
  font-size: 2.4rem
  line-height: 3.4rem
  @include xl
    font-size: 2rem
    line-height: 2.8rem
</style>
