import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ButtonBase from '@/elements/ButtonBase.vue';
import SvgIcon from '@/elements/SvgIcon.vue';
import TextField from '@/elements/TextField.vue';
import TextArea from '@/elements/TextArea.vue';
import LabelField from '@/elements/LabelField.vue';

Vue.component('ButtonBase', ButtonBase);
Vue.component('TextField', TextField);
Vue.component('TextArea', TextArea);
Vue.component('svg-icon', SvgIcon);
Vue.component('LabelField', LabelField);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
