<template>
  <div class="instagram-modal">
    <div v-if="imagesLoaded" class="modal__carousel" @click.stop>
      <div class="back__block" @click="close">
        <svg-icon class="modal-back" name="modal_back" />
        <h5 class="back-text">Повернутись</h5>
      </div>
      <v-carousel eager height="500px">
        <template v-slot:prev="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <svg-icon class="prev-btn" name="slider_prev" />
          </div>
        </template>
        <template v-slot:next="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <svg-icon class="next-btn" name="slider_next" />
          </div>
        </template>
        <v-carousel-item
          v-for="(item, i) in imagesPreLoader"
          :key="i"
          eager
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </div>
    <div v-if="getModalProps.text" class="modal__text" @click.stop>
      {{ getModalProps.text }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      images: [],
      imagesLoaded: false,
      imagesPreLoader: [],
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  created() {
    let sortedKeys = Object.keys(this.getModalProps).sort();
    let i = 0;
    for (const key in sortedKeys) {
      if (sortedKeys[key].includes('image')) {
        this.images.push(this.getModalProps[sortedKeys[key]]);
        let image = new Image();
        image.src = this.getModalProps[sortedKeys[key]];
        i++;
        this.imagesPreLoader.push(image);
      }
    }
    this.imagesLoaded = true;
  },
  methods: {
    close() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
  },
};
</script>

<style lang="sass" scoped>
.instagram-modal
  display: flex
  background-color: $white
  padding: 10rem 0 0 0
  z-index: -1
  @include l
    flex-wrap: wrap
    gap: 5rem

.back__block
  cursor: pointer
  position: absolute
  display: none
  top: -10rem
  align-items: center
  gap: 0.8rem
  right: 10rem
  padding: 0 1.5rem 0 0
  @include m
    display: flex
  @include m
    right: 5rem
  @include ms
    right: 0rem
.modal-back
  width: 6rem
  display: none
  @include xl
    display: inline

.back-text
  display: none
  font-size: 1.6rem
  @include xl
    display: block

.modal__carousel
  // flex: 1 0 50%
  position: relative
  width: 70rem
  margin: auto
  @include xl
    width: 60rem
  @include m
    width: 40rem
  @include ms
    width: 30rem

.modal__text
  // flex: 1 0 50%
  text-align: start
  margin: auto
  padding: 0 5rem
  transform: translate(0, -8rem)
  @include m
    padding: 3rem 1.5rem 0
  // @include ms
  //   padding: 3rem 1.5rem 0

.prev-btn, .next-btn
  cursor: pointer
</style>
