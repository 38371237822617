<template>
  <div class="footer">
    <a class="link-dark" href="https://www.ed-era.com" target="_blank">
      <svg-icon class="footer-logo" name="footer_logo"
    /></a>

    <div class="footer__links">
      <p>© 2014-2022 Educational era</p>
      <p class="footer-right">
        <a
          class="link-dark"
          href="https://www.ed-era.com/tos/#privacy_policy"
          target="_blank"
        >
          Політика конфіденційності</a
        >
      </p>
      <p>
        <a
          class="link-dark"
          href="https://www.ed-era.com/tos/#cookies"
          target="_blank"
        >
          Політика використання cookies</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.footer
  display: flex
  align-items: center
  gap: 1.7rem
  justify-content: space-between
  @include m
    align-items: start
    gap: 4.2rem

.footer-logo
  max-height: 4rem
  max-width: 8.6rem
  display: inline

.footer-right
  margin-left: auto
  @include m
    margin-left: 0

.footer__links
  display: flex
  gap: 3.7rem
  justify-content: space-between
  flex-grow: 1
  // width: 100%
  // flex: 0 0 100%
  font-weight: 400
  @include m
    flex-direction: column
    gap: 0.6rem
  & p
    font-size: 1.4rem
    line-height: 2rem
  & a, p
    @include m
      font-size: 1.2rem
      line-height: 1.7rem

.link-dark
  color: $text-primary-color
</style>
