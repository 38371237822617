<template>
  <div class="auth">
    <h2>Адмінка</h2>
    <div class="auth__block">
      <LabelField id="title" title="Введіть адмінський пароль" />
      <TextField
        id="title"
        v-model.trim="password"
        name="text"
        type="text"
        tooltip-text=""
      />
    </div>
    <ButtonBase transparent class="auth__button" @click="signIn"
      >Увійти</ButtonBase
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      password: '',
    };
  },
  methods: {
    async signIn() {
      await this.$store.dispatch('signIn', this.password);
    },
  },
};
</script>

<style lang="sass" scoped>
.auth
  display: flex
  flex-direction: column
  gap: 2rem
  margin-bottom: 10rem

.auth__button
  max-width: 20rem

.auth__block
  display: flex
  flex-direction: column
  width: 30rem
</style>
