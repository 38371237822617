<template>
  <div class="admin">
    <Auth v-if="!getAdminRole" />
    <SuperAdmin v-if="getAdminRole === 'superAdmin'" />
    <UploadImages v-if="getAdminRole" />
    <EditBlock v-if="getAdminRole" />
    <SiteInfo v-if="getAdminRole" />
    <Contacts v-if="getAdminRole" />
  </div>
</template>

<script>
import Auth from '@/components/Admin/Auth.vue';
import SuperAdmin from '@/components/Admin/SuperAdmin.vue';
import UploadImages from '@/components/Admin/UploadImages.vue';
import EditBlock from '@/components/Admin/EditBlock.vue';
import SiteInfo from '@/components/Admin/SiteInfo.vue';
import Contacts from '@/components/Admin/Contacts.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    Auth,
    SuperAdmin,
    UploadImages,
    EditBlock,
    SiteInfo,
    Contacts,
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  computed: {
    ...mapGetters(['getAdminRole']),
  },
};
</script>
<style lang="sass" scoped>
.admin
  padding: 2rem 7.2rem
</style>
