<template>
  <div class="home">
    <Header />
    <Presentation />
    <ThemeSelector @categories-selected="setCategories" />
    <Instagram :categories-selected="categoriesSelected" />
    <Social />
    <Ticker />
    <TickerRight />
    <Ticker />
    <Contacts />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Layout/Header.vue';
import Presentation from '@/components/Main/Presentation.vue';
import ThemeSelector from '@/components/Main/ThemeSelector.vue';
import Instagram from '@/components/Main/Instagram.vue';
import Social from '@/components/Main/Social.vue';
import Ticker from '@/components/Main/Ticker.vue';
import TickerRight from '@/components/Main/TickerRight.vue';
import Contacts from '@/components/Main/Contacts.vue';
import Footer from '@/components/Layout/Footer.vue';

export default {
  name: 'Home',
  components: {
    Header,
    Presentation,
    ThemeSelector,
    Instagram,
    Social,
    Contacts,
    Footer,
    Ticker,
    TickerRight,
  },
  data() {
    return {
      categoriesSelected: [],
    };
  },
  methods: {
    setCategories(value) {
      this.categoriesSelected = value;
    },
  },
};
</script>
<style lang="sass" scoped>
.home
  padding: 2rem 7.2rem
  max-width: 141.6rem
  margin: 0 auto
  @include l
    padding: 2rem 1.5rem
</style>
