<template>
  <div class="instagram">
    <div v-if="getAllFramesData" class="instagram__content">
      <div
        v-for="(item, id) in filteredItems"
        :key="id"
        class="instagram__image"
      >
        <div @click="openModal(item)">
          <transition name="fade">
            <img
              v-show="allFramesData[item.title].loaded"
              :key="id"
              class="instagram__image-frame"
              :src="item.image_0"
              alt=""
              @load="imageLoaded(item.title)"
            />
          </transition>
        </div>
      </div>
    </div>
    <ButtonBase class="instagram__button" transparent big @click="loadMore"
      >Завантажити ще</ButtonBase
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    categoriesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      allFramesData: {},
      elementsOnPage: 12,
    };
  },
  computed: {
    ...mapGetters(['getAllFramesData']),
    filteredItems() {
      let filteredItems = [];
      if (this.allFramesData && this.categoriesSelected.length != 0) {
        let i = 0;
        for (const key in this.allFramesData) {
          if (
            this.allFramesData[key].categories
              .split(',')
              .some((r) => this.categoriesSelected.includes(r)) &&
            this.allFramesData[key].show
          ) {
            this.allFramesData[key].title = key;
            filteredItems.push(this.allFramesData[key]);
            // filteredItems[key] = this.allFramesData[key];
            i++;
          }
          // if (i >= this.elementsOnPage) {
          //   break;
          // }
        }
      }
      filteredItems.sort((a, b) => a.order - b.order);
      let onPageItems = filteredItems.slice(0, this.elementsOnPage);
      return onPageItems;
      // let filteredItems = {};
      // if (this.allFramesData && this.categoriesSelected.length != 0) {
      //   let i = 0;
      //   for (const key in this.allFramesData) {
      //     if (
      //       this.allFramesData[key].categories
      //         .split(',')
      //         .some((r) => this.categoriesSelected.includes(r)) &&
      //       this.allFramesData[key].show
      //     ) {
      //       filteredItems[key] = this.allFramesData[key];
      //       i++;
      //     }
      //     if (i >= this.elementsOnPage) {
      //       break;
      //     }
      //   }
      // }
      // const result = Object.keys(filteredItems)
      //   .sort((a, b) => filteredItems[a].order - filteredItems[b].order)
      //   .reduce(
      //     (_sortedObj, key) => ({
      //       ..._sortedObj,
      //       [key]: filteredItems[key],
      //     }),
      //     {}
      //   );
      // console.log(result);
      // return result;
    },
  },
  async created() {
    await this.$store.dispatch('getAllFramesData');
    this.allFramesData = this.getAllFramesData;
  },
  methods: {
    imageLoaded(key) {
      this.$set(this.allFramesData[key], 'loaded', true);
    },
    openModal(item) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'instagram-slider',
        props: item,
      });
    },
    loadMore() {
      this.elementsOnPage += 12;
    },
  },
};
</script>

<style lang="sass" scoped>
.instagram__content
  display: flex
  flex-wrap: wrap
  // grid-template-columns: repeat( auto-fill, 30rem )
  justify-content: center
  max-width: 150rem
  // flex-wrap: wrap
  gap: 2.4rem
  margin: 0 auto 8rem
  transition: height 2s ease-in-out
  // @include lm
  //   grid-template-columns: repeat( auto-fill, 25rem )
  //   gap: 2rem
  @include m
    // grid-template-columns: repeat( auto-fill, 16.8rem )
    gap: 0.9rem


.instagram__button
  margin: 0 auto

.instagram__image
  // width: 30rem
  // height: 30rem
  flex: calc(25% - 72px)
  max-width: 25%
  cursor: pointer
  border-radius: 0.6rem
  // overflow: hidden
  @include xl
    flex: calc(33% - 58px)
    max-width: 33%
  // @include lm
  //   width: 25rem
  //   height: 25rem

  @include m
    flex: calc(50% - 18px)
    max-width: 50%
  //   width: 16.8rem
  //   height: 16.8rem

.instagram__image-frame
  transition: all $transition-speed ease
  border-radius: 0.6rem
  &:hover
    filter: brightness(65%)

.fade-enter-active,
.fade-leave-active
    transition: opacity 1s

.fade-enter,
.fade-leave-to
    opacity: 0
</style>
