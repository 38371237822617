<template>
  <div class="text_area">
    <v-textarea v-model="getValue" :label="label" solo></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      count: 0,
      countWithoutTrim: 0,
    };
  },
  computed: {
    getValue: {
      get() {
        this.count = this.value.trim().length;
        this.$emit('count-description', this.count);
        return this.value;
      },
      set(value) {
        let newValue = value;
        if (this.count > 560) {
          newValue = value.slice(0, 560);
        }
        this.$emit('text-area-value', newValue);
      },
    },
  },
};
</script>

<style lang="sass">
.v-textarea .v-input__control .v-input__slot
  box-shadow: none !important
  border: 1px solid var(--color-grey) !important
  padding: 0 !important
  padding-left: 12px !important
  font-size: 12px !important

.v-text-field--outlined fieldset
  border-color: var(--color-grey) !important
</style>
