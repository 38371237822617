<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h2>Інформація на сайті</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="info">
          <div class="text__block">
            <LabelField id="title" title="Кількість переглядів:" />
            <TextField
              id="title"
              v-model.trim="amountOfViews"
              :placeholder="amountOfViews"
              name="text"
              type="text"
              tooltip-text=""
            />
          </div>
          <ButtonBase transparent class="upload__button" @click="save"
            >Зберегти</ButtonBase
          >
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      amountOfViews: '',
    };
  },
  computed: {},
  async created() {
    this.amountOfViews = await this.$store.dispatch('getAmountOfViews');
  },
  methods: {
    async save() {
      await this.$store.dispatch('updateAmountOfViews', this.amountOfViews);
      this.amountOfViews = await this.$store.dispatch('getAmountOfViews');
    },
  },
};
</script>

<style lang="sass" scoped>
.info
  display: flex
  flex-direction: column
  gap: 2rem
  margin-bottom: 10rem

.upload__button
  max-width: 20rem

.text__block
  display: flex
  flex-direction: column
  width: 30rem

.category-select__block
  display: flex
  gap:2rem
  flex-wrap: wrap

textarea
  background: $white
  border: 1px solid $border-grey-color
  color: $text-primary-color
  font-size: 12px
  line-height: 18px
  font-weight: 400
  padding: 12px 18px

textarea::active
  border: 1px solid $border-grey-color
</style>
