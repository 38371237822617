import { db, storage } from '../../db.js';
import { ref, list, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  serverTimestamp,
  collection,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
} from 'firebase/firestore';

export const sendContacts = async function (document) {
  let timeStamp = Date.now().toString();
  await setDoc(doc(db, 'contacts', timeStamp), {
    ...document,
  });
};

export const uploadImage = async function (file, folder, filename) {
  const imageRef = ref(storage, `images/${folder}/${filename}`);
  await uploadBytes(imageRef, file).then((snapshot) => {
    // console.log('Uploaded a blob or file!');
  });
  const responce = await getDownloadURL(
    ref(storage, `images/${folder}/${filename}`)
  );
  return responce;
};

export const getAllFramesData = async function () {
  const querySnapshot = await getDocs(collection(db, 'topics'));
  let framesData = {};
  querySnapshot.forEach((doc) => {
    framesData[doc.id] = doc.data();
  });
  return framesData;
};

export const getImageURL = async function (path) {
  const responce = await getDownloadURL(ref(storage, path));
  return responce;
};

export const writeDoc = async function (folder, document) {
  await setDoc(doc(db, 'topics', folder), {
    ...document,
  });
};

export const getFrame = async function (folder) {
  const docRef = doc(db, 'topics', folder);
  const responce = await getDoc(docRef);
  return responce;
};

export const hideFrame = async function (folder) {
  const frankDocRef = doc(db, 'topics', folder);
  await updateDoc(frankDocRef, {
    show: false,
  });
};

export const showFrame = async function (folder) {
  const frankDocRef = doc(db, 'topics', folder);
  await updateDoc(frankDocRef, {
    show: true,
  });
};

export const deleteFrame = async function (folder) {
  await deleteDoc(doc(db, 'topics', folder));
};

export const swapItems = async function (title, order) {
  const frankDocRef = doc(db, 'topics', title);
  await updateDoc(frankDocRef, {
    order: order,
  });
};

export const getAmountOfViews = async function () {
  const docRef = doc(db, 'info', 'site_data');
  const docSnap = await getDoc(docRef);
  return docSnap.data().amount_of_views;
};

export const updateAmountOfViews = async function (data) {
  const docRef = doc(db, 'info', 'site_data');
  await updateDoc(docRef, {
    amount_of_views: data,
  });
};

export const getPasswords = async function () {
  const docRef = doc(db, 'auth', 'passwords');
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

export const updateAdminPassword = async function (data) {
  const docRef = doc(db, 'auth', 'passwords');
  await updateDoc(docRef, {
    admin: data,
  });
};

export const updateSuperAdminPassword = async function (data) {
  const docRef = doc(db, 'auth', 'passwords');
  await updateDoc(docRef, {
    superadmin: data,
  });
};

export const changeOrder = async function (data) {
  const docRef = doc(db, 'topics', data.topic);
  await updateDoc(docRef, {
    order: data.order,
  });
};

export const getContacts = async function () {
  const querySnapshot = await getDocs(collection(db, 'contacts'));
  let contacts = {};
  querySnapshot.forEach((doc) => {
    contacts[doc.id] = doc.data();
  });
  return contacts;
};
