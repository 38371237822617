<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h2>Редагувати фрейми</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="edit">
          <ThemeSelector @categories-selected="setCategories" />
          <ButtonBase @click="randomize">Перемішати</ButtonBase>
          <div
            v-if="getAllFramesData"
            :key="keyForUpdate"
            class="edit__content"
          >
            <div
              v-for="(item, key, id) in filteredItems"
              :key="id"
              class="edit__block"
            >
              <div class="image_block" @click="openModal(item)">
                <transition name="fade">
                  <img
                    :key="id"
                    class="edit__image"
                    :src="item.image_0"
                    alt=""
                    @load="imageLoaded(key)"
                  />
                </transition>
              </div>
              <div class="control_block">
                <p class="control_row">Назва</p>
                <p class="control_row">Є на головній сторінці?</p>
              </div>
              <div class="status_block">
                <p class="control_row">{{ item.title }}</p>
                <p v-if="item.show" class="control_row">Так</p>
                <p v-else class="control_row">Ні</p>
              </div>
              <div class="buttons_block">
                <ButtonBase v-if="item.show" @click="hide(item)"
                  >Приховати</ButtonBase
                >
                <ButtonBase v-else transparent @click="show(item)"
                  >Показати</ButtonBase
                >
                <ButtonBase red @click="deleteConfirm(item)"
                  >Видалити</ButtonBase
                >
              </div>
              <div class="order_block">
                <p>Порядок: {{ item.order }}</p>
                <ButtonBase v-if="item.order > 0" @click="orderUp(item)"
                  >Вище ↑</ButtonBase
                >
                <ButtonBase
                  v-if="item.order < Object.keys(filteredItems).length - 1"
                  transparent
                  @click="orderDown(item)"
                  >Нижче ↓</ButtonBase
                >
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// import PhotoUpload from '@/elements/PhotoUpload.vue';
import ThemeSelector from '@/components/Main/ThemeSelector.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ThemeSelector,
  },
  props: {},
  data() {
    return {
      keyForUpdate: 0,
      categoriesSelected: [],
      items: [],
      allFramesData: {},
    };
  },
  computed: {
    ...mapGetters(['getAllFramesData']),
    filteredItems() {
      let filteredItems = {};
      if (this.allFramesData && this.categoriesSelected.length != 0) {
        for (const key in this.allFramesData) {
          if (
            this.allFramesData[key].categories
              .split(',')
              .some((r) => this.categoriesSelected.includes(r))
          ) {
            filteredItems[key] = this.allFramesData[key];
            filteredItems[key].title = key;
          }
        }
      }
      const result = Object.keys(filteredItems)
        .sort((a, b) => filteredItems[a].order - filteredItems[b].order)
        .reduce(
          (_sortedObj, key) => ({
            ..._sortedObj,
            [key]: filteredItems[key],
          }),
          {}
        );
      return result;
    },
  },
  async created() {
    await this.$store.dispatch('getAllFramesData');
    this.allFramesData = { ...this.getAllFramesData };
  },
  methods: {
    async randomize() {
      console.log(Object.keys(this.allFramesData).length);
      let orderArray = [];
      for (
        let index = 0;
        index < Object.keys(this.allFramesData).length;
        index++
      ) {
        orderArray.push(index);
      }
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
      }
      shuffleArray(orderArray);
      console.log(orderArray);
      // for (let index = 0; index < orderArray.length; index++) {
      //   let payload = { order: orderArray[index] };
      // }
      let i = 0;
      for (const key in this.allFramesData) {
        let payload = { order: orderArray[i], topic: key };
        await this.$store.dispatch('changeOrder', payload);
        i++;
      }
      await this.$store.dispatch('getAllFramesData');
      this.allFramesData = { ...this.getAllFramesData };
    },
    deleteConfirm(item) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'delete-confirm',
        props: item,
      });
    },
    async hide(item) {
      await this.$store.dispatch('hideFrame', item.title);
      await this.$store.dispatch('getAllFramesData');
      this.allFramesData = { ...this.getAllFramesData };
    },
    async show(item) {
      await this.$store.dispatch('showFrame', item.title);
      await this.$store.dispatch('getAllFramesData');
      this.allFramesData = { ...this.getAllFramesData };
    },
    setCategories(value) {
      this.categoriesSelected = value;
    },
    imageLoaded(key) {
      this.$set(this.allFramesData[key], 'loaded', true);
    },
    openModal(item) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'instagram-slider',
        props: item,
      });
    },
    async orderUp(item) {
      let nextObject = { ...this.filteredItems };
      Object.keys(nextObject).forEach((key) => {
        if (nextObject[key].order != item.order - 1) delete nextObject[key];
      });
      let payload = {
        titleFirst: item.title,
        titleSecond: nextObject[Object.keys(nextObject)[0]].title,
        orderFirst: item.order,
        orderSecond: nextObject[Object.keys(nextObject)[0]].order,
      };
      await this.$store.dispatch('swapItems', payload);
      await this.$store.dispatch('getAllFramesData');
      this.allFramesData = { ...this.getAllFramesData };
      this.keyForUpdate++;
    },
    async orderDown(item) {
      let nextObject = { ...this.filteredItems };
      Object.keys(nextObject).forEach((key) => {
        if (nextObject[key].order != item.order + 1) delete nextObject[key];
      });
      let payload = {
        titleFirst: item.title,
        titleSecond: nextObject[Object.keys(nextObject)[0]].title,
        orderFirst: item.order,
        orderSecond: nextObject[Object.keys(nextObject)[0]].order,
      };
      await this.$store.dispatch('swapItems', payload);
      await this.$store.dispatch('getAllFramesData');
      this.allFramesData = { ...this.getAllFramesData };
      this.keyForUpdate++;
    },
  },
};
</script>

<style lang="sass" scoped>
.edit__content
  margin-top: 2rem
  display: flex
  flex-direction: column
  gap: 2rem

.edit__image
  width: 15rem
  min-width: 15rem
  height: 15rem
  cursor: pointer
  // border-right: 1px solid $text-primary-color

.edit__block
  display: flex
  gap: 2rem
  border: 1px solid $text-primary-color
  @include xl
    flex-direction: column

.control_block, .status_block, .buttons_block, .order_block
  border-left: 1px solid $text-primary-color
  padding: 2rem

.status_block
  width: 30rem

.buttons_block, .order_block
  display: flex
  flex-direction: column
  gap: 1rem

.control_row
  line-height: 4rem
  white-space: nowrap

.order_block
  white-space: nowrap
</style>
