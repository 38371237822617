<template>
  <label :for="id" class="label">{{ title }}</label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  display: inline-block
  font-weight: 500
  font-size: 12px
  line-height: 18px
  color: #AAAAAA
  margin-bottom: 8px
</style>
