<template>
  <div class="header">
    <img class="logo-circle" src="@/assets/icons/logo_circle.png" alt="" />
    <!-- <svg-icon class="logo-circle" name="logo_circle" /> -->
    <svg-icon class="header-logo" name="safe_logo" />
    <div class="links__block">
      <a
        href="https://instagram.com/edera_ua?utm_medium=copy_link"
        target="_blank"
      >
        <svg-icon class="social-logo" name="instagram_logo" />
      </a>
      <a href="https://www.facebook.com/EdEraUa" target="_blank">
        <svg-icon class="social-logo" name="fb_logo" />
      </a>
      <a href="https://t.me/EdEra_ua" target="_blank">
        <svg-icon class="social-logo" name="telegram_logo" />
      </a>
      <a
        href="https://invite.viber.com/?g2=AQADdgyazBTe703G7B8WnP2zXxsEdo5fCSm%2FWoxnUTRLu2eXkD3vaZLILLSy8GW4&lang=ru"
        target="_blank"
      >
        <svg-icon class="social-logo" name="viber_logo" />
      </a>
      <ButtonBase class="header__button" transparent @click="goToContacts()"
        >Долучитись</ButtonBase
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    goToContacts() {
      this.$router.push({ hash: '#contacts' });
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  position: relative
  justify-content: space-between
  align-items: center

.logo-circle
  position: absolute
  width: 35rem
  height: 18rem
  left: -7rem
  top: -2rem
  z-index: 10
  opacity: 0
  transition: all $transition-speed ease
  &:hover
    opacity: 1



.header-logo
  max-width: 23rem

.social-logo
  max-width: 2.3rem
  max-height: 2.3rem
  fill: $social-background
  &:hover
    filter: brightness(65%)
    transition: filter $transition-speed ease

.links__block
  display: flex
  align-items: center
  gap: 3rem
  @include l
    display: none

.header__button
  @include l
    display: none
</style>
