<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h2>Змінити адмінські паролі</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="super-admin">
          <div class="super-admin__block">
            <LabelField id="title" title="Введіть новий адмінський пароль" />
            <TextField
              id="title"
              v-model.trim="adminPassword"
              :placeholder="currentPasswords.admin"
              name="text"
              type="text"
              tooltip-text=""
            />
          </div>
          <ButtonBase
            transparent
            class="super-admin__button"
            @click="updateAdminPassword"
            >Змінити пароль адміна</ButtonBase
          >
          <div class="super-admin__block">
            <LabelField
              id="title"
              title="Введіть новий суперадмінський пароль"
            />
            <TextField
              id="title"
              v-model.trim="superAdminPassword"
              :placeholder="currentPasswords.superadmin"
              name="text"
              type="text"
              tooltip-text=""
            />
          </div>
          <ButtonBase
            transparent
            class="super-admin__button"
            @click="updateSuperAdminPassword"
            >Змінити пароль суперадміна</ButtonBase
          >
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      adminPassword: '',
      superAdminPassword: '',
      currentPasswords: {
        admin: '',
        superadmin: '',
      },
    };
  },
  async created() {
    this.currentPasswords = await this.$store.dispatch('getCurrentPasswords');
  },
  methods: {
    async updateAdminPassword() {
      await this.$store.dispatch('updateAdminPassword', this.adminPassword);
    },
    async updateSuperAdminPassword() {
      await this.$store.dispatch(
        'updateSuperAdminPassword',
        this.superAdminPassword
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.super-admin
  display: flex
  flex-direction: column
  gap: 2rem
  margin-bottom: 10rem

.super-admin__button
  max-width: 35rem

.super-admin__block
  display: flex
  flex-direction: column
  width: 35rem
</style>
