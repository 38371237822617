import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBBaGrzBP7Ix-8ClyDTsPPr-0jl4d54EP8',
  authDomain: 'edera-30787.firebaseapp.com',
  projectId: 'edera-30787',
  storageBucket: 'edera-30787.appspot.com',
  messagingSenderId: '74952737602',
  appId: '1:74952737602:web:768da4b80e063fe153bedb',
  measurementId: 'G-WWX8PNFXYS',
  databaseURL: 'gs://edera-30787.appspot.com',
});

// export const firebaseApp = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(firebaseApp);
export const db = getFirestore();
export const storage = getStorage(firebaseApp);
