<template>
  <div data-app class="app">
    <router-view />
    <!-- <Home /> -->
    <Modal />
  </div>
</template>

<script>
import Modal from '@/elements/modals/Modal.vue';

export default {
  name: 'App',
  components: { Modal },
};
</script>

<style>
.app {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@font-face {
  font-family: 'Inerta';
  src: local('Inerta'),
    url(./assets/fonts/Inerta-Regular.woff2) format('truetype');
}
</style>
