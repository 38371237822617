<template>
  <v-dialog
    v-model="getModalState"
    persistent
    fullscreen
    hide-overlay
    :max-width="`${modalWidth}px`"
    @keydown.esc="close"
    @click:outside="close"
  >
    <div class="modal modal__container" @click="close">
      <div class="modal__container_content">
        <div class="modal__container_wrapper-cross" @click="close">
          <svg-icon name="modal_cross" />
        </div>
        <component
          :is="modalName"
          @go-to-route="closeModalWithRouting"
        ></component>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import InstagramSliderModal from '@/elements/modals/InstagramSliderModal.vue';
import DeleteConfirmModal from '@/elements/modals/DeleteConfirmModal.vue';
import ContactUsModal from '@/elements/modals/ContactUsModal.vue';
import ContactUsConfirmModal from '@/elements/modals/ContactUsConfirmModal.vue';

export default {
  components: {
    InstagramSliderModal,
    DeleteConfirmModal,
    ContactUsModal,
    ContactUsConfirmModal,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('modal', ['getModalState', 'getModalName', 'getModalProps']),
    modalName() {
      return this.getModalName ? `${this.getModalName}-modal` : '';
    },
    // otherModalWidth() {
    //   switch (this.getModalName) {
    //     case 'animal-activity-map':
    //       return 'auto !important';
    //   }
    // },
    modalWidth() {
      switch (this.getModalName) {
        case 'delete-confirm':
          return 500;
        case 'instagram-slider':
        case 'contact-us':
        case 'contact-us-confirm':
          return 1440;
        default:
          return 940;
      }
    },
  },

  methods: {
    close() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    closeModalWithRouting(path) {
      this.close();
      this.$router.push(path);
    },
  },
};
</script>
<style lang="sass" scoped>
.modal__container
  height: 100%

.modal__container_wrapper-cross
  position: fixed
  top: 4.8rem
  right: 4.8rem
  width: 40px
  height: 40px
  & svg
    cursor: pointer
  @include m
    width: 30px
    height: 30px
  @include m
    display: none


.modal__container_content
  background-color: $white
  height: 100%

// .modal__container_wrapper-cross
//   position: absolute
//   top: -40px
//   right: -40px
//   width: 50px
//   height: 50px
//   padding: 13px
//   background-color: $white
//   cursor: pointer
//   z-index: 99
//   @include s
//     top: -21px
//     right: -16px

.icon-cross
  width: 24px
  height: 24px
</style>

<!--<style lang="sass">-->
<!--// .v-dialog-->
<!--//   width: auto !important-->

<!--.v-dialog:not(.v-dialog&#45;&#45;fullscreen)-->
<!--  max-width: 940px-->
<!--  // overflow: hidden-->
<!--  overflow-y: unset-->
<!--// overflow-x: hidden-->

<!--.v-dialog__content-->
<!--  width: 100%-->

<!--.modal-->
<!--  position: relative-->
<!--  background: $white-->
<!--  &::before-->
<!--    content: ""-->
<!--    position: absolute-->
<!--    min-width: 100%-->
<!--    height: 24px-->
<!--    background: $gradient-->
<!--    z-index: 15-->

<!--.modal__container_content-->
<!--  position: relative-->
<!--  padding: 68px 0-->

<!--.modal__container_inner-->
<!--  display: flex-->
<!--  flex-direction: column-->
<!--  align-items: center-->

<!--.modal__container_wrapper-icon-->
<!--  width: 50px-->
<!--  height: 50px-->
<!--  display: flex-->
<!--  align-items: center-->
<!--  justify-content: center-->
<!--  background: $gradient-->
<!--  border-radius: 50%-->

<!--.icon-letter-->
<!--  width: 22px-->
<!--  height: 20px-->

<!--.modal__title-->
<!--  line-height: 32px-->
<!--  margin: 24px 0-->

<!--.modal__sub-title-->
<!--  width: 515px-->
<!--  margin: 0 auto-->
<!--  line-height: 32px-->
<!--  font-weight: 100-->
<!--  color: #8D8D8D-->
<!--  text-align: center-->

<!--.icon-check-sm-->
<!--  width: 19px-->
<!--  height: 16px-->

<!--.modal__custom-title-->
<!--  margin-bottom: 50px-->

<!--.modal__btn&#45;&#45;wide.btn__main-->
<!--  padding: 16px 80px-->

<!--  .btn__icon-->
<!--    margin-left: 12px-->

<!--.modal__btn&#45;&#45;full.btn__main-->
<!--  width: 100%-->
<!--</style>-->
