<template>
  <div class="contact" @click.stop>
    <div class="contact__content">
      <svg-icon class="contact-kitten" name="contact_kitten" />
      <div class="back__block" @click="close">
        <svg-icon class="contact-back" name="contact_back" />
        <p>Закрити</p>
      </div>
      <div class="contact__text">
        <h4 class="contact__text-main">
          Ми отримали ваше повідомлення і вже розглядаємо його. Незабаром
          зв'яжемося з вами. До зустрічі!
        </h4>
      </div>
      <div class="buttons__block">
        <ButtonBase contact-transparent class="close-button" @click="close"
          >Закрити</ButtonBase
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      contactDetails: {
        name: '',
        contact: '',
        text: '',
      },
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  created() {},
  methods: {
    close() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    async sendContactToDB() {
      await this.$store.dispatch('sendContact', this.contactDetails);
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'contact-us-confirm',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.contact
  padding: 20rem 12rem
  @include l
    padding: 15rem 1.5rem

.contact__content
  min-height: 40rem
  display: flex
  flex-direction: column
  position: relative
  max-width: 85rem
  background-color: #9A95A3
  margin: auto
  padding: 4.8rem
  color: $white
  gap: 7rem
  border-radius: 0.4rem
  // & > div
    // flex: 1 0 45%
  @include l
    flex-direction: column
    gap: 3rem
    min-height: 27rem


.contact__text-main
  font-size: 2.4rem
  line-height: 3.4rem
  // @include l
  //   font-size: 1.8rem
  //   line-height: 2.5rem
  @include ms
    font-size: 1.8rem
    line-height: 2.5rem

.contact-arrow
  position: absolute
  width: 10rem
  left: -12rem
  top: -7rem
  @include l
    display: none

.contact-kitten
  position: absolute
  width: 6rem
  height: 6rem
  top: -5rem
  right: 3rem

.back__block
  display: none
  position: absolute
  color: $text-primary-color
  top: -4rem
  gap: 1.2rem
  left: 0
  align-items: center
  cursor: pointer
  @include l
    display: flex

.contact-back
  width: 6rem
  height: 3rem

.contact__text
  display: flex
  flex-direction: column
  justify-content: space-between

.contact__form
  display: flex
  flex-direction: column
  gap: 2.4rem

.buttons__block
  position: relative
  display: flex
  gap: 2.4rem
  justify-content: space-between
  margin-top: auto
  margin-left: auto
  @include l
    margin: 2rem auto 0
  & button
    flex-grow: 1

.contact__text-field
  background-color: transparent
  color: $white
  border: 1.4px solid #FFFFFF
  border-radius: 6px
  font-size: 1.6rem
  padding: 12px 18px
  &::placeholder
    color: $white
    font-size: 1.6rem
  &:focus
    outline: none

.close-button
  // @include l
  //   display: none

.contact__text-secondary-l
  font-size: 1.2rem
  line-height: 1.44rem
  @include l
    display: none

.contact__text-secondary-m
  font-size: 1.2rem
  line-height: 1.44rem
  display: none
  @include l
    display: block
    margin: 0 auto
    text-align: center
</style>
