<template>
  <div class="social">
    <div class="links__block">
      <p class="info-left">Всі дописи публікуємо у наших мережах щодня</p>
      <svg-icon class="circle-left" name="social_circle_left" />
      <svg-icon class="arrow-left" name="social_arrow_left" />
      <svg-icon class="arrow-left-m" name="social_arrow_left_m" />
      <a
        href="https://instagram.com/edera_ua?utm_medium=copy_link"
        target="_blank"
      >
        <svg-icon class="social-logo" name="instagram_logo" />
      </a>
      <a href="https://www.facebook.com/EdEraUa" target="_blank">
        <svg-icon class="social-logo" name="fb_logo" />
      </a>
      <a href="https://t.me/EdEra_ua" target="_blank">
        <svg-icon class="social-logo" name="telegram_logo" />
      </a>
      <a
        href="https://invite.viber.com/?g2=AQADdgyazBTe703G7B8WnP2zXxsEdo5fCSm%2FWoxnUTRLu2eXkD3vaZLILLSy8GW4&lang=ru"
        target="_blank"
      >
        <svg-icon class="social-logo" name="viber_logo" />
      </a>
      <p class="info-right">Загалом {{ amountOfViews }} млн. переглядів</p>
      <svg-icon class="circle-right" name="social_circle_right" />
      <svg-icon class="arrow-right" name="social_arrow_right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      amountOfViews: 0,
    };
  },
  async created() {
    this.amountOfViews = await this.$store.dispatch('getAmountOfViews');
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.social
  display: flex
  align-items: center
  padding: 20rem 0 10rem
  @include l
    padding: 30rem 0 10rem
  @include m
    padding: 30rem 0 5rem

.links__block
  position: relative
  display: flex
  align-items: center
  gap: 7rem
  margin: 0 auto
  @include m
    gap: 5rem


.social-logo
  width: 5.8rem
  height: 5.8rem
  fill: $social-background
  &:hover
    filter: brightness(65%)
    transition: all $transition-speed ease
  @include l
    width: 4.8rem
    height: 4.8rem
  @include m
    width: 4.2rem
    height: 4.2rem

.info-left
  position: absolute
  max-width: 18rem
  text-align: center
  left: -29rem
  top: -17.5rem
  @include l
    left: 0rem

.circle-left
  position: absolute
  left: -34rem
  top: -22rem
  width: 28rem
  height: 16rem
  @include l
    left: -6rem

.arrow-left
  position: absolute
  left: -18rem
  top: -10rem
  width: 15rem
  height: 16rem
  @include l
    display: none

.arrow-left-m
  display: none
  position: absolute
  left: 18rem
  top: -16rem
  width: 15rem
  height: 16rem
  @include l
    display: block

.info-right
  position: absolute
  max-width: 18rem
  text-align: center
  right: -29rem
  top: -14.5rem
  @include l
    display: none

.circle-right
  position: absolute
  right: -34rem
  top: -21rem
  width: 28rem
  height: 16rem
  @include l
    display: none

.arrow-right
  position: absolute
  right: -16rem
  top: -11rem
  width: 15rem
  height: 16rem
  @include l
    display: none
</style>
